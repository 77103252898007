<template>
	<div class="list-manage contract-details">
		<section class="list-manage-table">
			<div class="table-title"><span class="title">设备地图</span><span class="device-map"
					@click="$router.push('/personalCenter/myDevice').catch(() => { })">我的设备</span></div>
			<div class="contract-details-main">
				<Map style="width:820px" :locationList="locationList"></Map>
			</div>
		</section>
	</div>
</template>

<script>
import Map from "@/components/map";
import { getDevice } from "@/api/myorder";
export default {
	data() {
		return {
			locationList: []
		};
	},
	mounted() {
		this.getDeviceInfo()
	},
	components: {
		Map
	},
	methods: {
		//获取设备信息
		getDeviceInfo() {
			getDevice().then((res) => {
				res.data.list.forEach((item) => {
					let obj = {
						device_lease_id: item.device_lease_id,
						lat: item.latitude,
						lng: item.longitude
					}
					this.locationList.push(obj)
					
				})
			})
		}
	}
}
</script>

<style scoped>
.contract-details-main {
	width: 820px;
	height: 530px;
}
</style>
